import React from 'react';
import StoreDroidImg from '@/assets/store-droid.webp';
import StoreAppleImg from '@/assets/store-apple.webp';

export function DownloadApp({ blogUtm }: any) {
  return (
    <div id="download" className="p-4 py-6 my-20 text-center bg-[#9D77EE]">
      <h2 className=" md:text-4xl text-3xl mb-7 px-2 text-[#141414]">
        Download Level SuperMind Now:
      </h2>

      <div className="flex md:gap-14 gap-6 flex-wrap justify-center items-center">
        {/* eslint-disable-next-line */}
        <style jsx>{`
          img {
            max-width: 15rem;
          }
        `}</style>

        <a
          href={
            blogUtm ? blogUtm : `https://lsm.lvl.fit?data=guya8119qpa1psiuzwii`
          }
          className="border-4 border-[#7356e8] bg-black rounded-[1.4rem] py-2"
        >
          {/* eslint-disable-next-line */}
          <img
            src={StoreDroidImg.src}
            alt="download level app"
            width={StoreDroidImg.width}
            height={StoreDroidImg.height}
          />
        </a>

        {/* eslint-disable-next-line */}
        <a
          href={
            blogUtm ? blogUtm : `https://lsm.lvl.fit?data=guya8119qpa1psiuzwii`
          }
          className="border-4 border-[#7356e8] bg-black rounded-[1.4rem] py-2"
        >
          {/* eslint-disable-next-line */}
          <img
            src={StoreAppleImg.src}
            alt="download level app"
            width={StoreAppleImg.width}
            height={StoreAppleImg.height}
          />
        </a>
      </div>
    </div>
  );
}
