import React from 'react';
import Head from 'next/head';

/* eslint-disable */

export function Meta(props: {
  url?: string;
  desc?: string;
  title?: string;
  image?: string;
  canonical?: string;
  name?: string;
  content?: string;
  isBlog?: Boolean;
}) {
  //console.log'props :', props);
  const url = props.url || `https://www.level.game`;
  const desc =
    props.desc ||
    `Discover the tools to get a SuperMind, join the community of SuperMinds and learn from our affirmations, blogs, and meditations for a supercharged mind.`;
  const title = props.title || `Level | Supercharge Your Mind`;
  const image = props.image || `https://i.ibb.co/b6RnzCq/metaimg.png`;

  return (
    <Head>
      <title className="">{title}</title>

      <meta name="title" content={title} />
      <meta name="description" content={desc} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={desc} />
      <meta property="twitter:image" content={image} />
      <meta
        name="google-site-verification"
        content="M6mLBzlwKDc2JO-GTdmTK0L2XIexsg7A1UcQhqNEETQ"
      />
      <meta
        name="facebook-domain-verification"
        content="hztcld2h6tdyab8o2xxwcggpszo6bv"
      />

      <link rel="canonical" href={props.canonical || url} />

      {props.canonical && props.isBlog && (
        <>
          <link rel="alternate" hrefLang="en" href={props.canonical || url} />
          <link
            rel="alternate"
            hrefLang="kn"
            href={`${props?.canonical}?lang=kn` || url}
          />
          <link
            rel="alternate"
            hrefLang="mr"
            href={`${props?.canonical}?lang=mr` || url}
          />
          <link
            rel="alternate"
            hrefLang="gu"
            href={`${props?.canonical}?lang=gu` || url}
          />
          <link
            rel="alternate"
            hrefLang="hi"
            href={`${props?.canonical}?lang=hi` || url}
          />
          <link
            rel="alternate"
            hrefLang="bn"
            href={`${props?.canonical}?lang=bn` || url}
          />
          <link
            rel="alternate"
            hrefLang="ta"
            href={`${props?.canonical}?lang=ta` || url}
          />
        </>
      )}
    </Head>
  );
}
